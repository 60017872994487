import React, { useEffect, useState } from 'react';

import Speaking from './Speaking';
import SuccessApplication from './SuccessApplication';

const Preview = () => {

    return ( 
        <>
        <SuccessApplication/>
        </>
    );
}

export default Preview;