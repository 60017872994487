import {useNavigate} from 'react-router-dom';
import background from "../../images/LandingPage.webp";
import "./landingpage.css";

const scrollToFirst = () => {

}
const SpecifiedButton = ({route, text}) => {
    const navigate = useNavigate();
    return (

        
        <button className="specified-button" 
        onClick={() => {
        route.includes('http') ? 
        window.location.href = route 
        : navigate(route)
        }}>
            {text}
        </button>

    )
}

const LandingPage = () => {

    const buttons = [
        {route: "/the-prompt", text: "Get our Magazine"},
        // {route: "/speaking", text: "Our Workshops"},
        {route: "/work-with-us", text: "Apply"},
        // change to instagram 
        {route: "https://www.instagram.com/uazaicore/", text: "Follow Us"}
    ];

    return (
        <>
            <div className="main-container">
                <div style={{paddingLeft: "20vw", paddingRight: "20vw"}}>
                    <h1 className="small-title" >AI is changing the world</h1>
                    <h1 className="big-title" >So will you</h1>
                    <h1 className="big-title" >AI CORE</h1>
                </div>

                <div className="button-container" >
                    {buttons.map((button, index) => (
                        <SpecifiedButton key={index} route={button.route} text={button.text}/>
                    ))}
                    
                </div>

                <p style={{color: "white", textShadow: "1px 1px 8px black"}}>Scroll down to learn more...</p>
            </div>
        </>
    )
}

export default LandingPage;