import React, { useState, useEffect } from "react";
import axios from "axios";

import '../styles.css';

import titleImage from '../images/mainframe.png';

const SendEmailToUs = () => {
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const urlFormatting = (message) => {
        return message.split(/\s+/).join("-");
    }

    const handleSendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('message', message);

        try {
            console.log(urlFormatting(subject));
            console.log(urlFormatting(message));
            let toEmail = "aicore@arizona.edu"
            let url = `https://mail.google.com/mail/u/0/?fs=1&to=${toEmail}&su=${urlFormatting(subject)}&body=${urlFormatting(message)}&tf=cm`;

            console.log(url);

            window.location.href = url;

            console.log("Email sent successfully!");
            setEmail("");
            setSubject("");
            setMessage("");
            
        } catch (error) {
            alert("There was an error sending the email. Please try again later.");
        }
    }

    return (
        <>
        <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
            <div className="animated_title">Connect With Us</div>
        </div>
        <form onSubmit={handleSendEmail}>
            <div className="content_block" style={{ background: "var(--white)" }}>
                <div className="content">
                    <h1 style={{fontSize: "50px"}}>Email Us</h1>
                    <p>Send us an email with any questions, comments, or concerns you may have. We will get back to you as soon as possible.</p>
                    <div className="form_label">Your Email</div>
                    <input style={{marginTop: "10px", marginBottom: "10px", width: "300px"}}  type="text" placeholder="Desired email to hear back from us" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <br/>
                    <div className="form_label"> Your Title</div>
                    <input style={{marginTop: "10px", marginBottom: "10px", width: "300px"}} type="text" placeholder="Subject" value={subject}  onChange={(e) => setSubject(e.target.value) } required/>
                    <br/>
                    <div className="form_label"> Your Message</div>
                    <textarea style={{marginTop: "10px", marginBottom: "10px", minWidth: "400px", minHeight: "200px"}} value={message} placeholder="Your message" onChange={(e) => setMessage(e.target.value)} required/>
                    <br/>
                    <button type="submit">Send Email</button>
                </div>
            </div>
        </form>
        </>
    );
};

export default SendEmailToUs;