import React from "react";

const Card = ({ image, title, subtitle, name, onClick }) => (
  <div className="card" onClick={onClick}>
    <div className="card-image">
      <img src={image} alt={title} />
    </div>
    <div className="card-content">
      <div className="card-title">{title}</div>
      <div className="card-subtitle">{subtitle}</div>
      <div className="name">{name}</div> 
    </div>
  </div>
);

export default Card;
