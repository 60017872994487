import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import FileSaver from "file-saver";
import Popup from "./Popup";
import e from "../images/e.wav";
import topbarLogo from "../images/ua_wordmark.svg";
import ReactMarkdown from 'react-markdown';
import "../styles.css";

const CustomChat = (props) => {
  const {
    netID,
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    messagesInConversation,
    setMessagesInConversation,
    GPT,
    threadID,
    setThreadID,
    theme,
    token,
    setGPT,
    setNetID,
    setToken,
  } = props;

  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [fileNames, setFileNames] = useState();
  const [routeToChatBoolean, setRouteToChatBoolean] = useState(true);
  const [awsCitations, setAwsCitations] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenModel, setDropdownOpenModel] = useState(false);
  // const [processedMessages, setProcessedMessages] = useState()

  const navigate = useNavigate();

  const [dict, setDict] = useState({});

  const customGptModels = {
    "Dungeons and JoJo": {
      group: "A",
      image: require("../images/jojo.webp"),
      title: "Dungeons and JoJo",
      subtitle:
        "Dungeon Master GPT: Embark on a Thrilling Adventure: Create Unique Characters, Battle with Stands, and Uncover Mysterious Plots in Dungeons and JoJo! Experience Endless Fun with Dynamic Gameplay and Immersive Storytelling.",
      name: "- Nnanyere Okoro",
      assistantID: "asst_CEQI9xG2zPDt2i9PhCpAuXhP",
      vectorStoreID: "",
      codeInterpreter: false,
    },
    "Kevin's Kitchen Assistant": {
      group: "A",
      image: require("../images/kitchen.webp"),
      title: "Kevin's Kitchen Assistant",
      subtitle:
        "Kevin's Kitchen Assistant: Your Personalized Cooking Guide. Discover Delicious Recipes, Learn Essential Techniques, and Create Mouthwatering Dishes with Confidence!",
      name: "- Kevin",
      assistantID: "asst_QwRP01A5o3PCZVoQQYedl0xi", 
      vectorStoreID: "", 
      codeInterpreter: false
    },
    "MBTI Test Bot": {
      group: "B",
      image: require("../images/mbti.webp"),
      title: "MBTI Test Bot",
      subtitle:
        "Discover Your Personality Type: Take the MBTI Test and Unlock Insights into Your Strengths, Weaknesses, and Ideal Career Paths. Gain a Deeper Understanding of Yourself and Others!",
      name: "- Vivian Huynh",
      assistantID: "asst_WaLWYfAglLO1HYIl0igtopoj",
      vectorStoreID: "",
      codeInterpreter: false
    },
    "Pokemon AI Assistant": {
      group: "C",
      image: require("../images/pokemon.webp"),
      title: "Pokemon AI Assistant",
      subtitle:
        "Master Pokémon Battles with Expert Insights. Discover strengths, weaknesses, and ideal matchups. Build balanced teams for every stage of your Pokémon journey!",
      name: "- Joseph",
      assistantID: "asst_FWrijxF2zgAFtUFYQEjnUNdV",
      vectorStoreID: "",
      codeInterpreter: true,
    },
    "Levi's Maze Assistant": {
      group: "C",
      image: require("../images/maze.webp"),
      title: "Levi's Maze Assistant",
      subtitle:
        "Generate intricate mazes and explore the paths with interactive guidance. Test your skills, avoid pitfalls, and celebrate your victories!",
      name: "- Levi",
      assistantID: "asst_JyOhgBzxxVkCYvP82QvtCxTv",
      vectorStoreID: "",
      codeInterpreter: false,
    },
    "Study Advisor": {
      group: "C",
      image: require("../images/study.webp"),
      title: "Study Advisor",
      subtitle:
        "Tailored Study Guidance for Every Subject: Discover Optimal Methods, Create Customized Schedules, and Receive Continuous Feedback. Enhance Your Learning Efficiency and Achieve Your Goals!",
      name: "- Alex Myers",
    },
    "Tucson Birdwatching Bot": {
      group: "D",
      image: require("../images/birdwatching.webp"),
      title: "Tucson Birdwatching Bot",
      subtitle:
        "Explore Tucson's vibrant birdlife with expert guidance. Discover prime birdwatching spots, identify local species, and learn about conservation efforts. Your ultimate birdwatching companion awaits!",
      name: "- Seulgi Chung",
      assistantID: "asst_tdAZlglBWa1RMXsZpcRuCwS4",
      vectorStoreID: "vs_KDTEha0nC8IzVnnOIDic8Dpz",
      codeInterpreter: false,
    },
    
    "Ruy Lopez Chess Opening V.2": {
      group: "D",
      image: require("../images/chess.webp"),
      title: "Ruy Lopez Chess Opening V.2",
      subtitle:
        "Master the Ruy Lopez: Expert Chess Strategies, Move Analysis, and Interactive Guidance for Improving Your Opening Game. Unlock your potential with in-depth tutorials and personalized tips.",
      name: "- Aidan Rains",
      assistantID: "asst_Rem3H23Iowg0UTpKcBZD8ZGM",
      vectorStoreID: "",
      codeInterpreter: false,
    },
    "Excursion Amigos": {
      group: "D",
      image: require("../images/sedona.webp"),
      title: "Excursion Amigos",
      subtitle:
        "Discover Arizona's best eateries and activities for your road trip. Get personalized recommendations for each city and town. Explore hidden gems and local favorites to make the most of your Arizona adventure",
      name: "- Danielle N. Cunes",
      assistantID: "asst_NwsICEofluHYIteUt9LhhjLg",
      vectorStoreID: "vs_D0RkFOOpEpm673Y7r4ySBcDu",
      codeInterpreter: false,
    }

  };

  useEffect(() => {
    const currentConversation = conversations
      .filter((conversation) => conversation.id === selectedConversation)
      .sort((a, b) => a.SequenceNumber - b.SequenceNumber);

    const seenUris = new Set();

    currentConversation.forEach((obj) => {
      if (obj.citations) {
        let citationsArray = obj.citations.split(",");
        citationsArray = citationsArray.filter((citation) => {
          if (seenUris.has(citation)) {
            return false;
          } else {
            seenUris.add(citation);
            return true;
          }
        });
        obj.citations = citationsArray.map((citation, index) => {
          return { uri: citation, reference: `[${index + 1}]` };
        });
      }
    });

    setMessagesInConversation(currentConversation);
  }, [selectedConversation]);

  let backend_url = process.env.REACT_APP_BACKEND_URL;
  let login_url = backend_url + "/routes/login";

  const reLogin = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/routes/login`;
  };

  useEffect(() => {
    if (token === "null" || token === null) {
      const loginToChat = async () => {
        await reLogin();
      };

      loginToChat();
    }
  }, [netID]);

  // useEffect(() => {
  //   document.title = "Custom Chat | AI Core";

  //   if (location.pathname === "/custom-chat") {
  //     if (theme === "dark") {
  //       document.documentElement.style.backgroundColor = "#212121";
  //       document.body.style.backgroundColor = "#212121";
  //     } else {
  //       document.documentElement.style.backgroundColor = "white";
  //       document.body.style.backgroundColor = "white";
  //     }
  //   } else {
  //     document.body.style.backgroundColor = "white";
  //   }
  //   return () => {
  //     document.body.style.backgroundColor = "white";
  //   };
  // }, [location.pathname, theme]);

  useEffect(() => {
    document.title = "Custom Chat | AI Core";
  
    if (location.pathname === "/custom-chat") {
      if (theme === "dark") {
        document.documentElement.style.backgroundColor = "#212121";
        document.body.style.backgroundColor = "#212121";
  
        // Set text color to white for dark mode
        document.documentElement.style.setProperty('--main_text_color', 'white');
        document.documentElement.style.setProperty('--chat000', 'white');
      } else {
        document.documentElement.style.backgroundColor = "white";
        document.body.style.backgroundColor = "white";
  
        // Set text color to black for light mode
        document.documentElement.style.setProperty('--main_text_color', 'black');
        document.documentElement.style.setProperty('--chat000', 'black');
      }
    } else {
      document.body.style.backgroundColor = "white";
    }
  
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [location.pathname, theme]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesInConversation]);

  // const processMessage = async (e) => {
  //   let endResult = e.split('![');
  //   endResult = endResult.map((s) => 
  //       s.split(".png").map((t) => t.split(']('))[0][1] + ".png")
        
  //   return endResult;
  // };

  const processMessage = (message) => {
    const parts = message.split(/(!\[.*?\]\(.*?\.png\))/g);
    let processedMessages = [];
  
    parts.forEach(part => {
      if (part.startsWith('![') && part.includes('.png')) {
        const url = part.match(/\((.*?)\)/)[1];  // Extract URL within parentheses
        processedMessages.push({ type: 'image', src: url.trim() });
      } else if (part.trim()) {
        processedMessages.push({ type: 'text', content: part.trim() });
      }
    });
  

    console.log(processedMessages, 'process')
    return processedMessages;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      // console.log("GPT", GPT)
      // const customModel = customGptModels
      console.log(
        "accessing array with python syntax here",
        customGptModels.Alexs_Countries_Test
      );

      try {
        // Send the entire message history along with the new message to the backend

        console.log(customGptModels[GPT], "customGptModels[GPT]");
        console.log(
          customGptModels[GPT].assistantID,
          "customGptModels[GPT].assistantID"
        );
        console.log(
          customGptModels[GPT].vectorStoreID,
          "customGptModels[GPT].vectorStoreID"
        );
        console.log(
          customGptModels[GPT].codeInterpreter,
          "customGptModels[GPT].codeInterpreter"
        );
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-custom-chatbot`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: "GPT-4o",
            threadID: threadID, 
            group: customGptModels[GPT].group,
            assistantID: customGptModels[GPT].assistantID,
            vectorStoreID: customGptModels[GPT].vectorStoreID,
            codeInterpreter: customGptModels[GPT].codeInterpreter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        console.log(response, 'RES')
        // Update messages with the response
        if (response.data && response.data.message) {
          // console.log(processMessage(response.data.message.value));
          let resArray = processMessage(response.data.message.value);
          console.log(resArray,'res')

          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message.value,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);

          console.log(messagesInConversation, 'Updated')
          if (response.data.threadID && response.data.threadID !== threadID) {
            setThreadID(response.data.threadID);
          }
        }
      } catch (error) {
        console.error("Error in sendMessage:", error);
        setIsBotTyping(false);
      }
    }
  };

  const handleMessageSend = (event) => {
    sendMessage(event);
  };

  const getImagePath = (selectedModel) => {
    switch (selectedModel) {
      case "Gating Network":
        return require("../images/gating.png");
      case "Claude (Sonnet)":
        return require("../images/claude.jpg");
      case "Llama 3":
        return require("../images/llama.png");
      case "Gemini 1.5 Pro":
        return require("../images/gemini.jpg");
      case "Mistral":
        return require("../images/mistral.jpg");
      case "Wildcat News":
        return require("../images/wildcat.jpg");
      case "Expert Comedian":
        return require("../images/comedian.png");
      case "Groq":
        return require("../images/groq.jpg");
      case "Claude + Vault Bot":
        return require("../images/claude.jpg");
      case "Stable Diffusion":
        return require("../images/stability.jpg");
      case "GPT-4o":
        return require("../images/gpt4.png");
      default:
        return require("../images/default.webp");
    }
  };

  // TopbarCustomGPT UI elements

  const selectGPT = (selectedGPT) => {
    setGPT(selectedGPT);
    localStorage.setItem("GPT", JSON.stringify(selectedGPT));
    localStorage.removeItem("messages"); // Clear messages from local storage
    localStorage.removeItem("threadID"); // Clear thread from local storage
    setThreadID(null);
    setDropdownOpenModel(!dropdownOpenModel);
  };

  const ModelDropdownMenu = () => {
    return (
      <div className={`model_dropdown_menu ${dropdownOpenModel ? "show" : ""}`}>
        <button
          className="pick_model_button"
          onClick={() => {
            selectGPT("GPT-4o");
          }}
        >
          <img
            className="pick_gpt_image"
            src={require("../images/gpt4.png")}
            alt=""
          />
          <div
            className={`pick_model_text ${GPT === "GPT-4o" && "model_active"}`}
          >
            GPT-4o
          </div>
          <br />
        </button>
      </div>
    );
  };

  return (
    <>
      <ModelDropdownMenu />
  
      <div style={{ paddingTop: "30px", marginLeft: "260px" }}>
        <div className="chat_container">
          <div className="messages_container">
          {messagesInConversation
  ?.filter((message) => message.text !== "NULL")
  .map((message, index) => {
    const processedMessages = processMessage(message.text); // Process the message into parts
    return (
      <div key={index}>
        <div
          className="individual_message_container"
          style={{
            justifyContent:
              message?.sender === "bot" || message?.sender === "assistant"
                ? "left"
                : "",
          }}
        >
          <div>
            {message?.sender === "bot" || message?.sender === "assistant" ? (
              <img
                className="bot-profile-pic"
                src={getImagePath(message?.selectedModel)}
                alt=""
              />
            ) : (
              ""
            )}
          </div>

          <div
            className={`individual_message_bubble ${
              message?.sender === "user" ? "message_sender" : ""
            }`}
          >
            {processedMessages.map((part, i) => (
              <div key={i} className="individual_message_part">
                {part.type === "text" ? (
                  // <p className="individual_message_part">{part.content}</p> // Display text parts
                  <ReactMarkdown >{part.content}</ReactMarkdown>
                ) : (
                  <img
                    src={part.src}
                    alt="Image"
                    style={{ maxWidth: "100%", height: "auto" }} // Display images
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  })}
            {isBotTyping && (
              <div
                className="individual_message_container"
                style={{ justifyContent: "left" }}
              >
                <img
                  className="bot-profile-pic"
                  src={
                    GPT === "Gating Network"
                      ? require("../images/gating.png")
                      : GPT === "Claude (Sonnet)"
                      ? require("../images/claude.jpg")
                      : GPT === "Llama 3"
                      ? require("../images/llama.png")
                      : GPT === "Mistral"
                      ? require("../images/mistral.jpg")
                      : GPT === "Wildcat News"
                      ? require("../images/wildcat.jpg")
                      : GPT === "Expert Comedian"
                      ? require("../images/comedian.png")
                      : GPT === "GPT-4o"
                      ? require("../images/gpt4.png")
                      : GPT === "Gemini 1.5 Pro"
                      ? require("../images/gemini.jpg")
                      : GPT === "Stable Diffusion"
                      ? require("../images/stability.jpg")
                      : GPT === "Groq"
                      ? require("../images/groq.jpg")
                      : GPT === "Claude + Vault Bot"
                      ? require("../images/claude.jpg")
                      : require("../images/default.webp")
                  }
                  alt=""
                />
  
                <div className="individual_message_bubble">
                  <div className="typing-animation">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
        <br />
  
        <div className="send_message_bar">
          <div style={{ marginLeft: "260px" }}>
            <TextareaAutosize
              name="input"
              type="text"
              className="form_chatbox chatpage"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={`Message GPT-4o...`}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleMessageSend(e);
                }
              }}
            />
            <button
              type="submit"
              className="send_message_button"
              disabled={!input}
              onClick={handleMessageSend}
            >
              <i
                className="material-symbols-outlined inline-icon"
                style={{ color: "var(--chat900)" }}
              >
                arrow_upward
              </i>
            </button>
            <br />
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "12px",
                textAlign: "center",
                color: "var(--chat000)",
                opacity: "0.6",
              }}
            >
              Powered by AI Core at the University of Arizona.
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
};

export default CustomChat;
