import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './WebcamCapture.css'; // Import custom CSS for styling

function WebcamCapture() {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]); // State to store the chat history
  const [inputText, setInputText] = useState(''); // State to handle text input in Artistic Mode
  const [mode, setMode] = useState('description'); // State to track the selected mode
  const [generatedImage, setGeneratedImage] = useState(''); // For artistic mode only

  // Capture photo and add it to the chat history
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    
    if (mode === 'description') {
      // Add image to chat history in Description Mode
      setChatHistory(prevHistory => [
        ...prevHistory,
        { type: 'image', image: imageSrc, text: '', id: prevHistory.length, mode }
      ]);
    } else if (mode === 'artist') {
      // In Artistic Mode, directly send the image to the backend
      sendArtisticImage(imageSrc);
    }
  };

  // Send the image and text input to the backend for artistic mode
  const sendArtisticImage = async (base64Image) => {
    setLoading(true); // Show loading indicator
    const base64Data = base64Image.split(',')[1]; // Remove data:image/jpeg;base64, part

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/artistic_image`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image: base64Data, additionalText: inputText }) // Send image and additional text
      });

      const data = await res.json();
      // Set the generated artistic image
      setGeneratedImage(data.message);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  // Send the current image and input text for Description Mode
  const sendToBackend = async (imageId) => {
    setLoading(true); // Show loading indicator

    // Find the current chat item based on imageId
    const currentChatItem = chatHistory.find(item => item.id === imageId);
    
    // Extract base64 image data by removing "data:image/jpeg;base64," part
    const base64Image = currentChatItem.image.split(',')[1];
  
    try {
      // Send only the latest image and the associated text to the backend
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/process_image`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          image: base64Image, // Send the base64 image
          prompt: currentChatItem.text // Send the associated text prompt
        })
      });
  
      const data = await res.json();
      // Update the chat history with the response
      setChatHistory(prevHistory =>
        prevHistory.map(item =>
          item.id === imageId ? { ...item, response: data.message } : item
        )
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };


  const compressImage = (base64Str, maxWidth = 500, maxHeight = 500, quality = 0.7) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
  
        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        // Compress the image and return the new base64 string (JPEG format)
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality); // quality is a number between 0 and 1
        resolve(compressedBase64);
      };
    });
  };
  
  const sendToBackendMultipleImages = async (imageId) => {
    setLoading(true); // Show loading indicator
  
    // Compress all base64 images from the chat history before sending them
    const compressedImages = await Promise.all(chatHistory.map(async (item) => {
      const compressedImage = await compressImage(item.image); // Compress each image

      return {
        base64Image: compressedImage.split(',')[1], // Remove data:image/jpeg;base64, part
        prompt: item.text // The associated text prompt
      };
    }));

  
    try {
      // Send the compressed images and their corresponding prompts to the backend
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/process_images`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ images: compressedImages }) // Send the array of compressed base64 images and their prompts
      });
  
      const data = await res.json();
      // Handle the response as needed (update the chat history or display output)


       // Update the chat history with the response from the API
       setChatHistory(prevHistory =>
        prevHistory.map(item =>
          item.id === imageId ? { ...item, response: data.message } : item
        )
      );
    
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  // Handle text input change for each image
  
  
  const handleInputChange = (e, imageId) => {
    const value = e.target.value;
    setChatHistory(prevHistory =>
      prevHistory.map(item => (item.id === imageId ? { ...item, text: value } : item))
    );
  };

  return (
    <div className="webcam-container">
      <div className="webcam-wrapper">
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
        <div className="buttons-container">
          <button 
            className={mode === 'description' ? 'active' : ''} 
            onClick={() => setMode('description')}
          >
            Description Mode
          </button>
          <button 
            className={mode === 'artist' ? 'active' : ''} 
            onClick={() => setMode('artist')}
          >
            Artistic Mode
          </button>
        </div>
        <button onClick={capture}>Capture Photo</button>
      </div>

      {/* Display loading indicator if API is processing */}
      {loading && <div className="loading">Processing...</div>}

      {/* Chat for Description Mode */}
      {mode === 'description' && chatHistory.length > 0 && (
        <div className="chat-box">
          <h3>Chat History</h3>
          <ul className="chat-history">
            {chatHistory.map((item, index) => (
              <li key={index} className={`chat-item ${item.mode}`}>
                {item.type === 'image' && (
                  <div className="image-container">
                    <img src={item.image} alt={`Captured ${index}`} className="captured-image" />
                    {/* Text input for each image */}
                    <textarea
                      placeholder="Ask something about this image..."
                      value={item.text}
                      onChange={(e) => handleInputChange(e, item.id)}
                      className="text-input"
                    />
                    <button
                      // onClick={() => sendToBackend(item.id)}
                      onClick={() => sendToBackendMultipleImages(item.id)}
                      disabled={loading || !item.text.trim()}
                    >
                      {loading ? 'Processing...' : 'Send'}
                    </button>
                    {/* Display the response below */}
                    {item.response && <p className="response">{item.response}</p>}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Text input and generated image for Artistic Mode */}
      {mode === 'artist' && (
        <div>
          <textarea
            placeholder="Enter additional artistic input..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="text-input"
          />
          {generatedImage && (
            <div className="image-container">
              <h3>Generated Artistic Image</h3>
              <img src={generatedImage} alt="Artistic Rendering" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default WebcamCapture;
