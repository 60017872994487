import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import '../styles.css';

const Admin = () => {

    useEffect(() => {
        document.title = "Admin | AI Core";
        fetchData();
    }, []);

    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const [jobApplications, setJobApplications] = useState([]);
    const [zoomRequests, setZoomRequests] = useState([]);
    const [workshopMembers, setWorkshopMembers] = useState([]);
    const [mailingList, setMailingList] = useState([]);
    const [name, setName] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureName, setProfilePictureName] = useState("");
    const [prompts, setPrompts] = useState([]);
    const [newPrompt, setNewPrompt] = useState("");
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState("");

    const [conversationData, setConversationData] = useState([]);
    const [openConversations, setOpenConversations] = useState({});
    const [selectedNetID, setSelectedNetID] = useState('All');

    const [dataExists, setDataExists] = useState(false);
    const [error, setError] = useState([]);

    // Available job positions
    const allPositions = ["AI Engineer", "Coder", "Marketer", "Product Manager", "XR Specialist", "UI/UX Designer", "Logistics Specialist", "Videographer", "Digital Marketing Specialist", "Sales Manager"];

    // Fetches admin data
    const fetchData = async () => {
        try {
            setError("");

            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/get-admin-data`,
                method: 'GET',
                responseType: 'json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  }
            });
            const { jobApplications, zoomRequests, workshopMembers, mailingList, chatbotSettings, conversations, users } = response.data;

            setJobApplications(jobApplications);
            setZoomRequests(zoomRequests);
            setWorkshopMembers(workshopMembers);
            setMailingList(mailingList);
            setConversationData(conversations)
            
            setName(chatbotSettings.name);
            setPrompts(chatbotSettings.prompts);

            setUsers(users);

            setDataExists(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            navigate('/');
        }
    };

    // Deletes resume from view
    // Documented out for debugging
    async function deleteResume(position, email, major, graduation, date) {

        try {
            let jobApplications1 = [...jobApplications];
            for (let i = 0; i < jobApplications.length; i++) {
                if (jobApplications1[i].Position === position && 
                    jobApplications1[i].Email === email && 
                    jobApplications1[i].Major === major && 
                    jobApplications1[i].Graduation === graduation &&
                    jobApplications1[i].Date === date) {
    
                    await jobApplications1.splice(i, 1);
                    break;
                }
            }             
            await setJobApplications(jobApplications1);


            await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/remove-job-application`,
                method: 'POST',
                responseType: 'json',
                data: { position, email, major, graduation, date },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  }
            });

        } catch (error) {
            console.error("Error deleting resume:", error);
        }
    }

    // Saves changes to chatbot
    const saveChanges = async () => {
        // Remove any empty strings from the end of the prompts array
        const updatedPrompts = prompts.filter(prompt => prompt.trim() !== '');

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/routes/update-chatbot`, {
                name, profilePicture, prompts: updatedPrompts,
            });
            alert('Chatbot updated successfully.');
        } catch (error) {
            console.error("Error saving data:", error);
            alert('Failed to update chatbot.');
        }
    };

    // Saves user permissions
    const saveUsers = async () => {
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/update-users`,
                method: 'POST',
                responseType: 'json',
                data: {users: users.filter(user => user.NetID.trim() !== '')},
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  }
            });
            alert('Users updated successfully.');
        } catch (error) {
            console.error("Error saving data:", error);
            alert('Failed to update users.');
        }
    };

    // Triggers file input when the upload button is clicked
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    // Handles file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePicture(file);
            setProfilePictureName(file.name);
        }
    };

    // Handles changes to chatbot prompts
    const handlePromptChange = (index, newValue) => {
        const updatedPrompts = [...prompts]; // Clone the current prompts array
        updatedPrompts[index] = newValue; // Update the value at the specific index
        setPrompts(updatedPrompts); // Set the updated prompts array
    };

    // Function to delete a prompt at a given index
    const deletePrompt = (index) => {
        // Use the filter method to return all prompts that aren't at the given index
        const updatedPrompts = prompts.filter((_, promptIndex) => index !== promptIndex);
        setPrompts(updatedPrompts);
    };

    // Function to add a new prompt
    const addPrompt = () => {
        if (newPrompt.trim() !== '') {
            const updatedPrompts = [...prompts, newPrompt];
            setPrompts(updatedPrompts);
            setNewPrompt(''); // Clear the input after adding the prompt
        }
    };

    const handleUserChange = (index, newNetID) => {
        const updatedUsers = users.map((user, userIndex) => 
            index === userIndex ? { ...user, NetID: newNetID } : user
        );
        setUsers(updatedUsers);
    };
    
    const handleRoleChange = (index, newRole) => {
        const updatedUsers = users.map((user, userIndex) => 
            index === userIndex ? { ...user, Role: newRole } : user
        );
        setUsers(updatedUsers);
    };

    // Function to delete a prompt at a given index
    const deleteUser = (index) => {
        // Use the filter method to return all users that aren't at the given index
        const updatedUsers = users.filter((_, userIndex) => index !== userIndex);
        setUsers(updatedUsers);
    };

    // Function to add a new user
    const addUser = () => {
        if (newUser.trim() !== '') {
            const updatedUsers = [...users, { NetID: newUser, Role: "editor" }];
            setUsers(updatedUsers);
            setNewUser(''); // Clear the input after adding the user
        }
    };

    // Group messages by conversation ID

    const groupedConversations = conversationData.reduce((acc, message) => {
        acc[message.id] = acc[message.id] || [];
        acc[message.id].push(message);
        return acc;
    }, {});

    // Sort conversations by most recent message
    const sortedConversations = Object.entries(groupedConversations)
        .map(([id, messages]) => ({ id, messages }))
        .sort((a, b) => new Date(b.messages[b.messages.length - 1].Date) - new Date(a.messages[a.messages.length - 1].Date));

    // Toggle conversation open/close state
    const toggleConversation = (id) => {
        setOpenConversations((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    // Extract unique NetIDs
    const uniqueNetIDs = ['All', ...new Set(conversationData.map((message) => message.NetID))];

    // Filter conversations by selected NetID
    const filteredConversations = sortedConversations.filter((conversation) => selectedNetID === 'All' || conversation.messages.some((message) => message.NetID === selectedNetID));

    return (
        <>
        {!dataExists ? <><div className="content_block" style={{ background: "#e2e9eb" }}>
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "30px" }}>Admin Panel</div>
                <div>Fetching data...</div>
            </div>
        </div></> : <>

        <div className="content_block" style={{ background: "#e2e9eb" }}>
            <div className="content" style={{ paddingBottom: "0px" }}>
                <button type="button" onClick={() => {{setTab(0)}}} className={`button ${tab !== 0 && `button_outlined`}`} style={{ marginRight: "15px" }}>Job applications</button>
                <button type="button" onClick={() => {{setTab(1)}}} className={`button ${tab !== 1 && `button_outlined`}`} style={{ marginRight: "15px" }}>Email list</button>
                <button type="button" onClick={() => {{setTab(2)}}} className={`button ${tab !== 2 && `button_outlined`}`} style={{ marginRight: "15px" }}>Permissions</button>
                <button type="button" onClick={() => {{setTab(3)}}} className={`button ${tab !== 3 && `button_outlined`}`} style={{ marginRight: "15px" }}>Chatbot prompts</button>
                <button type="button" onClick={() => {{setTab(4)}}} className={`button ${tab !== 4 && `button_outlined`}`}>Chatbot data</button>
            </div>
        </div>
        
        {tab === 0 ? <>

            <div className="content_block" style={{ background: "#e2e9eb" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "30px" }}>Job Applications</div>

                    <div style={{ background: "white", padding: "20px", paddingBottom: "15px", paddingLeft: "25px", paddingRight: "25px", boxSizing: "border-box", borderRadius: "10px" }}>
                        <div className="bubble_title" style={{ marginBottom: "10px", color: "var(--blue)" }}>Round 2 Candidates</div>
                        <div className="bubble_text">There are no candidates in round 2.</div>
                    </div>

                    <div className="horizontal_line" style={{ marginTop: "20px", marginBottom: "20px" }}></div>

                    {allPositions.map((position) => (
                        <div key={position} style={{ marginBottom: "20px" }}>
                            <div className="bubble_title" style={{ marginBottom: "10px" }}>{position}</div>
                            {jobApplications?.filter(app => app.Position === position).length > 0 ? jobApplications.filter(app => app.Position === position).map((app, index) => (
                                <div key={index} className="app_bubble">
                                    <span className="bubble_text">{index + 1}. <span className="text_red">{app.Email}</span></span>&nbsp;&nbsp;
                                    <span className="bubble_text">Major: <span className="text_red">{app.Major?.toLowerCase()}</span></span>&nbsp;&nbsp;
                                    <span className="bubble_text">Graduation: <span className="text_red">{app.Graduation?.toLowerCase()}</span></span>&nbsp;&nbsp;
                                    {app.Url && <><span className="bubble_text">url: <a href={app.Url} target="_blank" className="link">view url</a></span>&nbsp;&nbsp;</>}

                                    <a href={app.ResumeLink} target="_blank" className="link">view resume</a>&nbsp;&nbsp;
                                    <a href={app.CVLink} target="_blank" className="link">view cover letter</a>&nbsp;&nbsp;
                                    <button onClick={() => deleteResume(app.Position, app.Email, app.Major, app.Graduation, app.Date)} className="button">Delete</button>
                                    {/*<span className="link" style={{ color: "var(--blue)" }}>move to round 2</span>&nbsp;&nbsp;*/}
                                </div>
                            )) : <span className="bubble_text">There are no applications for this position at this time.</span>}
                        
                            <div className="horizontal_line" style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                        </div>
                    ))}
                </div>
            </div></>

            : tab === 1 ? <>
                
            <div className="content_block" style={{ background: "#e2e9eb" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "30px" }}>Email list</div>

                    <div className="bubble_title" style={{ marginTop: "30px", marginBottom: "10px" }}>Zoom Requests</div>
                    {zoomRequests?.length ? zoomRequests.map((request, index) => (
                        <div key={index}>
                            <span className="bubble_text">{index + 1}. <span className="text_red">{request.Email}</span></span>&nbsp;&nbsp;
                            <span className="bubble_text">comments: <span className="text_italic">"{request.Comments || 'none'}</span></span>"<br/>
                        </div>
                    )) : <span className="bubble_text">There are no zoom requests at this time.</span>}

                    <div className="bubble_title" style={{ marginTop: "30px", marginBottom: "10px" }}>Workshop Members</div>
                    {workshopMembers?.length ? workshopMembers.map((member, index) => (
                        <div key={index}>
                            <span className="bubble_text">{index + 1}. <span className="text_red">{member.Email}</span></span><br/>
                        </div>
                    )) : <span className="bubble_text">There are no workshop members at this time.</span>}

                    <div className="bubble_title" style={{ marginTop: "30px", marginBottom: "10px" }}>Mailing List</div>
                    {mailingList?.length ? mailingList.map((member, index) => (
                        <div key={index}>
                            <span className="bubble_text">{index + 1}. <span className="text_red">{member.Email}</span></span><br/>
                        </div>
                    )) : <span className="bubble_text">There is nobody on the mailing list.</span>}

                </div>
            </div></>

            : tab === 2 ? <>

            <div className="content_block" style={{ background: "#e2e9eb" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "25px" }}>Permissions</div>

                    <div className="bubble_text" style={{ marginTop: "0px" }}><span className="bubble_text_bold">Editor:</span> Able to modify chatbot</div>
                    <div className="bubble_text" style={{ marginTop: "5px", marginBottom: "25px" }}><span className="bubble_text_bold">Admin:</span> Able to modify chatbot, view database & manage permissions</div>

                    <div style={{ marginBottom: "6px" }}>
                        <span className="form_label">NetID</span>
                        <span className="form_label" style={{ marginLeft: "370px" }}>Role</span>
                    </div>
                    {users && users.map((user, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? '10px' : '0' }}>
                            <input type="text" className="form" value={user.NetID} onChange={(e) => handleUserChange(index, e.target.value)}/>
                            <select value={user.Role} onChange={(e) => handleRoleChange(index, e.target.value)} className="form" style={{ marginLeft: '10px' }}>
                                <option value="editor">Editor</option>
                                <option value="admin">Admin</option>
                            </select>
                            <button className="button" style={{ width: "45px", padding: "0px", marginLeft: "10px" }} onClick={() => deleteUser(index)}>
                                <i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>delete</i>
                            </button>
                        </div>
                    ))}

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <input type="text" className="form" value={newUser} placeholder="Enter a NetID" onChange={(e) => setNewUser(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') addUser(); }}/>
                        <button className="button" style={{ width: "45px", padding: "0px", marginLeft: "10px" }} onClick={addUser}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>add</i></button>
                    </div>

                    <button type="button" style={{ marginTop: "20px" }} onClick={saveUsers} className="button">Save changes</button>
                </div>
            </div></>

            : tab === 3 ? <>

            <div className="content_block" style={{ background: "#e2e9eb" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "25px" }}>Chatbot Prompts</div>

                    <div className="form_label">Name</div>
                    <input type="text" className="form" value={name} onChange={(e) => setName(e.target.value)}/><br/>

                    <div className="form_label">Profile picture</div>
                    <div style={{ display: "flex" }}>
                        <img style={{ width: "45px", height: "45px", marginRight: "10px" }} src={require('../images/default.webp')} alt=""/>
                        <input type="file" name="profilePicture" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange}/>
                        <button type="button" className="button_outlined" onClick={triggerFileInput}><i className="material-symbols-outlined inline-icon" style={{ color: "var(--red)" }}>upload</i> Upload File</button>
                    </div>
                    {profilePictureName && <span style={{ marginTop: "10px", fontSize: "16px" }}>{profilePictureName}</span>}

                    <div className="form_label">System prompts</div>
                    {prompts && prompts.map((prompt, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? '10px' : '0' }}>
                            <input type="text" className="form" value={prompt} onChange={(e) => handlePromptChange(index, e.target.value)}/>
                            <button className="button" style={{ width: "45px", padding: "0px", marginLeft: "10px" }} onClick={() => deletePrompt(index)}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>delete</i></button>
                        </div>
                    ))}

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <input type="text" className="form" value={newPrompt} placeholder="Enter new prompt" onChange={(e) => setNewPrompt(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') addPrompt(); }}/>
                        <button className="button" style={{ width: "45px", padding: "0px", marginLeft: "10px" }} onClick={addPrompt}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>add</i></button>
                    </div>

                    <button type="button" style={{ marginTop: "20px" }} onClick={saveChanges} className="button">Save changes</button>
                </div>
            </div></>
            
            : <>

            <div className="content_block" style={{ background: "#e2e9eb" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "30px" }}>Chatbot Data</div>
                    
                    <div style={{ display: "flex" }}>
                        <div className="bubble_title" style={{ marginBottom: "40px", color: "var(--blue)" }}>Filter by user:</div>
                        <select value={selectedNetID} onChange={(e) => setSelectedNetID(e.target.value)} style={{ height: "30px", marginTop: "-4px", marginLeft: "8px", fontSize: "17px", width: "160px" }}>
                            {uniqueNetIDs.map((netID) => (
                                <option key={netID} value={netID}>{netID}</option>
                            ))}
                        </select>
                    </div>

                    {filteredConversations.map((conversation) => (
                    <div key={conversation.id}>
                        <div style={{ display: "flex", width: "max-content" }} className="expandable" onClick={() => toggleConversation(conversation.id)}>
                            <div className="bubble_title" style={{ marginBottom: "0px" }}>Conversation {conversation.id}</div>
                            <button className="expand_convo"><i className="material-symbols-outlined inline-icon">expand_more</i></button>
                        </div>

                        {openConversations[conversation.id] && <>
                        
                        <div style={{ height: "13px" }}></div>
                        {conversation.messages.map((message, index) => (
                            <div key={index} className="app_bubble" style={{ maxWidth: "90%" }}>
                                <span className="bubble_text">{message.sender === "user" ? message.NetID : message.selectedModel ? message.selectedModel.toLowerCase() : "assistant"}: <span className="text_red">{message.text === "!IMAGE" ? "(generated image)" : message.text ? message.text : "(no response)"}</span></span>&nbsp;&nbsp;
                            </div>
                        ))}</>}
                        
                        <div className="horizontal_line" style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                    </div>
                    ))}
                </div>
            </div>
            </>
        }

        </>}
        </>
    );
}

export default Admin;