// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-icon {
    position: fixed;
    bottom: 30px;
    right: 100px; /* Adjust positioning as needed */
    border-radius: 50%;
    background: var(--red); /* Use the same variable or a different color */
    color: white;
    border: none;
    padding: 15px;
    padding-top: 19px; 
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1000; 
    width: 60px;
    height: 60px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  }
  
  .back-icon:hover {
    filter: brightness(1.05);
  }`, "",{"version":3,"sources":["webpack://./src/components/BackIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY,EAAE,iCAAiC;IAC/C,kBAAkB;IAClB,sBAAsB,EAAE,+CAA+C;IACvE,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gDAAgD;EAClD;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[".back-icon {\n    position: fixed;\n    bottom: 30px;\n    right: 100px; /* Adjust positioning as needed */\n    border-radius: 50%;\n    background: var(--red); /* Use the same variable or a different color */\n    color: white;\n    border: none;\n    padding: 15px;\n    padding-top: 19px; \n    box-sizing: border-box;\n    cursor: pointer;\n    z-index: 1000; \n    width: 60px;\n    height: 60px;\n    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);\n  }\n  \n  .back-icon:hover {\n    filter: brightness(1.05);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
