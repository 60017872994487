import React, { useState } from "react";
import topbarLogo from "../images/ua_wordmark.svg";
import axios from "axios";

import Popup from "./Popup";
import "./Sidebar.css"; // Import the new CSS file

const Sidebar = (props) => {
  const {
    netID,
    setNetID,
    theme,
    setTheme,
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
  } = props;

  let backend_url = process.env.REACT_APP_BACKEND_URL;
  let login_url = backend_url + "/routes/login";
  const [popup, setPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // handles theme change
  const toggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      localStorage.setItem("theme", "light");
    } else {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    }
  };

  // Create a list of unique IDs from the conversations array
  const uniqueConversationIds = [
    ...new Set(conversations.map((conversation) => conversation.id)),
  ];

  const conversationPreviews = uniqueConversationIds.map((id) => {
    const messagesById = conversations.filter(
      (conversation) => conversation.id === id
    );
    const firstMessage =
      messagesById.length > 0 && messagesById[0].text
        ? messagesById[0].text
        : "";
    return { id, firstMessage };
  });

  let randomId = Math.floor(Math.random() * (1000000 - 10000 + 1)) + 10000;

  // Function to open a popup if needed
  const handlePopup = (
    title,
    text,
    button_text = "",
    button_function = () => {}
  ) => {
    setPopupData({ title, text, button_text, button_function });
    setPopup(true);
  };

  // Function to delete a conversation (hides only from user)
  const deleteConvo = async (id) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/routes/delete-conversation`,
        method: "POST",
        responseType: "json",
        data: { convoToDelete: id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data) {
        setConversations(response.data.conversations);
      }
    } catch (error) {
      console.error("Error in deleteConvo:", error);
    }
  };

  return (
    <>
      <Popup
        popupData={popupData}
        popup={popup}
        setPopup={setPopup}
        setNetID={setNetID}
      />

      <nav className={`sidebar-container ${isSidebarOpen ? "open" : "closed"}`}>
        {/* Toggle Button for Sidebar */}
        <button className="toggle-sidebar-button" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <i className="material-icons">chevron_left</i>
          ) : (
            <i className="material-icons">chevron_right</i>
          )}
        </button>

        <a href="https://www.arizona.edu">
          <img
            className={`sidebar-topbar-logo ${
              theme === "light" ? "sidebar-invert-logo" : ""
            }`}
            src={topbarLogo}
            alt="Topbar Logo"
          />
        </a>

        <div style={{ marginTop: "50px" }}></div>
        <button
          disabled={!netID || netID === "null"}
          className="sidebar-item"
          onClick={() => {
            setSelectedConversation(randomId);
            localStorage.setItem("selectedConversation", randomId);
          }}
        >
          <i
            className="material-symbols-outlined"
            style={{
              color: "var(--chat000)",
              opacity: "0.7",
              marginRight: "6px",
            }}
          >
            edit_square
          </i>
          <div style={{ fontSize: "17px", color: "var(--chat000)" }}>
            &nbsp;&nbsp;New chat
          </div>
        </button>

        {conversationPreviews.map(({ id, firstMessage }, index) => (
          <div
            key={index}
            className={`sidebar-item ${
              selectedConversation === id ? "sidebar-item-selected" : ""
            }`}
            onClick={() => {
              setSelectedConversation(id);
              localStorage.setItem("selectedConversation", id);
            }}
          >
            <div style={{ fontSize: "17px", color: "var(--chat000)" }}>
              &nbsp;&nbsp;
              {firstMessage.length > 17
                ? firstMessage.substring(0, 17) + "..."
                : firstMessage}
            </div>
            <i
              className="material-symbols-outlined sidebar-inline-icon-dynamic"
              style={{
                color: "var(--chat000)",
                opacity: "0.7",
                alignItems: "flex-end",
              }}
              onClick={() => {
                deleteConvo(id);
              }}
            >
              delete
            </i>
          </div>
        ))}

        <div className="sidebar-bottom-buttons">
          <div className="sidebar-item" onClick={toggleTheme}>
            <i
              className="material-symbols-outlined"
              style={{
                color: "var(--chat000)",
                opacity: "0.7",
                marginRight: "6px",
              }}
            >
              {theme === "dark" ? "bedtime" : "sunny"}
            </i>
            <div style={{ fontSize: "17px", color: "var(--chat000)" }}>
              &nbsp;&nbsp;Theme
            </div>
          </div>

          <a href={!netID || netID === "null" ? login_url : "#"}>
            <div className="sidebar-item">
              {!netID || netID === "null" ? (
                <i
                  className="material-symbols-outlined"
                  style={{
                    color: "var(--chat000)",
                    opacity: "0.7",
                    marginRight: "6px",
                  }}
                >
                  login
                </i>
              ) : (
                <div className="sidebar-user-profile-circle">
                  {netID?.slice(0, 2).toUpperCase()}
                </div>
              )}
              <div style={{ color: "var(--chat000)", fontSize: "17px" }}>
                {netID && netID !== "null" ? netID : "Log in with NetID"}
              </div>
            </div>
          </a>

          {(!netID || netID === "null") && (
            <div
              className="sidebar-item"
              onClick={() => {
                handlePopup("Enter passcode", `XXX`);
              }}
            >
              <i
                className="material-symbols-outlined"
                style={{
                  color: "var(--chat000)",
                  opacity: "0.7",
                  marginRight: "6px",
                }}
              >
                lock
              </i>
              <div style={{ color: "var(--chat000)", fontSize: "17px" }}>
                Log in with passcode
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
