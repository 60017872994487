import React from 'react';

import footerLogo from '../images/ua_logo.svg';
import '../styles.css';

const Footer = () => {
    return (
        <div className="footer_container">
            <div className="footer_content">

                <div className="footer_logo_and_links">
                    <a href="https://www.aicore.arizona.edu"><img className="footer_logo" src={footerLogo} alt="Footer Logo"/></a>

                    <div className="footer_link_box">
                        <a className="footer_link" href="https://talent.arizona.edu/?_gl=1*1pg42q0*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDU2MTUuNTkuMC4w">Employment</a>
                        <a className="footer_link" href="https://cirt.arizona.edu/?_gl=1*1bt2k2c*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDYzMjMuNjAuMC4w">Emergency Information</a>
                        <a className="footer_link" href="https://www.arizona.edu/title-ix?_gl=1*fpq1gy*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDYzNjAuMjMuMC4w">Title IX / Non-Discrimination</a>
                        <a className="footer_link" href="https://safety.arizona.edu/?_gl=1*1yvpgso*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MDMuNjAuMC4w">Campus Safety</a>
                        <a className="footer_link" href="https://cirt.arizona.edu/ualert/ualert-services?_gl=1%2Ajmku2z%2A_ga%2AMjA0MDg1MTIyNC4xNjI4NzIzNzk3%2A_ga_7PV3540XS3%2AMTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MDkuNTQuMC4w">UAlert</a>
                        <a className="footer_link" href="https://www.arizona.edu/copyright?_gl=1*drn9ny*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MTQuNDkuMC4w">Copyright</a>
                        <a className="footer_link" href="https://www.arizona.edu/campus-accessibility?_gl=1*d7u717*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MjEuNDIuMC4w">Campus Accessibility</a>
                        <a className="footer_link" href="https://www.arizona.edu/contact-us?_gl=1*1i25br5*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MjYuMzcuMC4w">Contact Us</a>
                        <a className="footer_link" href="https://www.arizona.edu/website-feedback?_gl=1*1wog5do*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwNDI1OC42My4xLjE3MDU1MDY0MzEuMzIuMC4w">Feedback</a>
                    </div>
                </div>

                <div className="horizontal_line"></div>

                <div className="footer_info">
                    <div className="footer_info_box">
                        <span className="footer_title">INFORMATION FOR</span>
                        <a className="footer_info_link" href="https://www.arizona.edu/admissions?_gl=1*1dzf2gn*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4wLjE3MDU1MDk1MTAuNjAuMC4w">Future Students</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/students?_gl=1*1c4n1g0*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk1NzkuNjAuMC4w">Current Students</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/faculty-staff?_gl=1*10lwx0m*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk1OTAuNDkuMC4w">Faculty & Staff</a>
                        <a className="footer_info_link" href="https://alumni.arizona.edu/?_gl=1%2Amqu6mb%2A_ga%2AMjA0MDg1MTIyNC4xNjI4NzIzNzk3%2A_ga_7PV3540XS3%2AMTcwNTUwOTUxMC42NC4xLjE3MDU1MDk1OTUuNDQuMC4w">Alumni & Donors</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/parents-visitors?_gl=1*kmlee1*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MDQuMzUuMC4w">Parents & Visitors</a>
                        <a className="footer_info_link" href="https://corporate.arizona.edu/?_gl=1*16wk4rt*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MTAuMjkuMC4w">Corporations & Businesses</a>
                    </div>

                    <div className="footer_info_box">
                        <span className="footer_title">TOPICS</span>
                        <a className="footer_info_link" href="https://www.arizona.edu/about?_gl=1*zacba6*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MTYuMjMuMC4w">About the University</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/academics?_gl=1*cg3yh*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MjQuMTUuMC4w">Academics</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/arts-museums?_gl=1*1s00h2t*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MjkuMTAuMC4w">Arts & Museums</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/athletics-recreation?_gl=1*1y1110t*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2MzUuNC4wLjA.">Athletics & Recreation</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/colleges-schools?_gl=1*rcxn17*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NDEuNjAuMC4w" style={{ width: "200px" }}>Colleges, Schools, Departments</a>
                        <a className="footer_info_link" href="https://diversity.arizona.edu/?_gl=1*atmh96*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NDkuNTIuMC4w">Diversity</a>
                        <a className="footer_info_link" href="https://www.environment.arizona.edu/?_gl=1*1z12vx1*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NTUuNDYuMC4w" style={{ marginTop: "36px" }}>Environment & Sustainability</a>
                        <a className="footer_info_link" href="https://international.arizona.edu/?_gl=1*lky2oh*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NjEuNDAuMC4w">International Engagement</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/health-sciences?_gl=1*182a6mu*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NjYuMzUuMC4w">Health & Medical</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/libraries?_gl=1*15lkvue*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NzEuMzAuMC4w">Libraries</a>
                        <a className="footer_info_link" href="https://uaforyou.arizona.edu/?_gl=1*oe115f*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2NzYuMjUuMC4w">Outreach & Extension</a>
                        <a className="footer_info_link" href="https://research.arizona.edu/?_gl=1*5f7edp*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2ODEuMjAuMC4w">Research & Innovation</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/purpose-mission-values?_gl=1*9zhh98*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2ODUuMTYuMC4w">Purpose, Mission & Values</a>
                    </div>

                    <div className="footer_info_box">
                        <span className="footer_title">RESOURCES</span>
                        <a className="footer_info_link" href="https://directory.arizona.edu/?_gl=1*lnupdv*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk2OTguMy4wLjA.">Directory</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/calendars-events?_gl=1*1x0za1j*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3MDAuMS4wLjA.">Calendars</a>
                        <a className="footer_info_link" href="https://map.arizona.edu/?_gl=1%2a14anok2%2a_ga%2aMjA0MDg1MTIyNC4xNjI4NzIzNzk3%2a_ga_7PV3540XS3%2aMTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3MDYuNjAuMC4w">Campus Map</a>
                        <a className="footer_info_link" href="https://news.arizona.edu/?_gl=1*m3mfuz*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3MTIuNTQuMC4w">News</a>
                        <a className="footer_info_link" href="https://phonebook.arizona.edu/?_gl=1*d7j81x*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3MjMuNDMuMC4w">Phonebook</a>
                        <a className="footer_info_link" href="https://www.arizona.edu/weather?_gl=1*3b41i8*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3MzEuMzUuMC4w">Weather</a>
                    </div>

                    <div className="footer_info_box" style={{ width: "160px" }}>
                        <span className="footer_title">CONNECT</span>
                        <a className="footer_info_link" href="https://www.facebook.com/uarizona"><i className="fab fa-facebook-square" style={{ color: "#49595e" }}></i> Facebook</a>
                        <a className="footer_info_link" href="https://twitter.com/uarizona"><i className="fab fa-twitter-square" style={{ color: "#49595e" }}></i> Twitter</a>
                        <a className="footer_info_link" href="https://www.instagram.com/uazdata/"><i className="fab fa-instagram-square" style={{ color: "#49595e" }}></i> Instagram</a>
                        <a className="footer_info_link" href="https://www.linkedin.com/company/uarizonadata/"><i className="fab fa-linkedin" style={{ color: "#49595e" }}></i> LinkedIn</a>
                        <a className="footer_info_link" href="https://www.youtube.com/universityofarizona"><i className="fab fa-youtube-square" style={{ color: "#49595e" }}></i> YouTube</a>
                    </div>
                </div>

                <div className="horizontal_line"></div>

                <div className="footer_disclaimer">We respectfully acknowledge the University of Arizona is on the land and territories of Indigenous peoples. Today, Arizona is home to 22 federally recognized tribes, with Tucson being home to the O’odham and the Yaqui. Committed to diversity and inclusion, the University strives to build sustainable relationships with sovereign Native Nations and Indigenous communities through education offerings, partnerships, and community service.</div>

                <div className="horizontal_line"></div>

                <div className="footer_bottom_link" style={{ marginTop: "20px" }} onClick={() => {window.location.href=('https://www.arizona.edu/information-security-privacy?_gl=1*116yi6c*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3NjMuMy4wLjA.')}}>University Information Security and Privacy</div>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", flexWrap: "wrap" }}>
                    <div className="footer_bottom" style={{ marginBottom: "0px" }}>© 2024 The Arizona Board of Regents on behalf of&nbsp;</div>
                    <div className="footer_bottom_link" onClick={() => {window.location.href=('https://www.arizona.edu/?_gl=1*nj1glo*_ga*MjA0MDg1MTIyNC4xNjI4NzIzNzk3*_ga_7PV3540XS3*MTcwNTUwOTUxMC42NC4xLjE3MDU1MDk3NzYuNjAuMC4w')}}>The University of Arizona</div>
                    <div className="footer_bottom">.</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;