import React, { useState, useEffect } from "react";
import axios from "axios";

import titleImage from '../images/campus.jpg';
import '../styles.css';

const Workshops = () => {

    useEffect(() => {
        document.title = "Public Workshops | AI Core";
    }, []);

    const [email, setEmail] = useState("");
    const [subscribe, setSubscribe] = useState(false);
    const [requested, setRequested] = useState("");

    // // Handles signing up for a workshop
    // const handleSignup = async (e) => {
    //     e.preventDefault();
    //     if (!email) { return; }

    //     try {
    //         const response = await axios({
    //             url: `${process.env.REACT_APP_BACKEND_URL}/routes/register-for-workshop`,
    //             method: 'POST',
    //             responseType: 'json',
    //             data: { email: email, subscribe: subscribe },
    //         });

    //         console.log(response);

    //         // request success
    //         setRequested("Requested");
    //         setEmail("")
    //         setSubscribe(false);
    //     } catch (error) {
    //         setRequested("Error");
    //     }
    // };
    
    return (
        <>
        <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
            <div className="animated_title">Our Workshops</div>
        </div>

        {/* <div className="content_block" style={{ background: "var(--blue)" }}>
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "20px", color: "white" }}>Register Now</div>

                <div className="bubble_text" style={{ color: "white" }}>Reserve your spot and we'll send you more details!</div>
                
                <form onSubmit={handleSignup} style={{ marginTop: "30px", display: "flex", flexDirection: "column", color: "white" }}>
                    <input type="email" className="form" placeholder="wilburwildcat@arizona.edu " value={email} onChange={(e) => setEmail(e.target.value)}/>

                    <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" checked={subscribe} onChange={(e) => setSubscribe(e.target.checked)} />
                        <span style={{ marginLeft: "5px", fontSize: "16px", fontStyle: "italic", color: "rgba(255, 255, 255, 0.7)" }}>Subscribe to our mailing list</span>
                    </div>
                    
                    <button type="submit" className="button" style={{ marginTop: "15px", marginBottom: "15px" }}>Register</button>
                </form>

                {requested !== "" && <div className="bubble_text" style={{ fontStyle: "italic", color: "white" }}>{requested === "Requested" ? "Thanks for registering! We'll get back to you shortly." : "Internal server error."}</div>}
                {requested === "Error" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{`(${process.env.REACT_APP_BACKEND_URL}/routes/request-a-zoom did not return a response)`}</div>}
            </div>
        </div> */}

        <div className="content_block">
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "20px" }}>Workshops</div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="bubble">
                        <div className="bubble_title">AI in 2024 with AI Core</div>
                        <div className="tag" style={{ color: "var(--red)" }}>Registration Required</div>
                        <div className="bubble_text">90 minute workshops free to the public featuring the latest techniques and perspectives gleaned from the University of Arizona's AI Core workforce development program.</div>

                        <img className="bubble_image" src={require('../images/class.jpg')} alt=""/>
                        <a href="https://events.trellis.arizona.edu/en/f44lNu67/ai-core-spring-workshops-5a3U6R2lCCD/overview">
                            <button type="button" className="button">Register Now</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Workshops;