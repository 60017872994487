import React, { useState } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import "./Home2.css";
import aispeaking from '../images/AI_Core_Workshop.webp';
import theprompt from '../images/ThePromptMay24.webp';
import aiWorkshopImage from '../images/ai-workshop.jpg'; // Add your image paths
import rndImage from '../images/rnd.jpg';
import communityImage from '../images/community.jpg';
import XRExperience from "./XRExperience/XRExperience";

// Feature Block Component
const FeatureBlock = ({ title, description, image }) => (
    <div className="feature-block">
        {image && <img src={image} alt={title} className="feature-image" />}
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
    </div>
);

const CurrentNews = ({ url, title, bubbleTitle, text }) => (
    <div className="news-bubble">
        <div className="news-video-container">
            <ReactPlayer
                url={url}
                playing={false}
                width="100%"
                height="100%"
                controls
                style={{ borderRadius: "10px", overflow: "hidden" }}
            />
        </div>
        <div className="news-title">{title}</div>
        <div className="news-bubble-title">{bubbleTitle}</div>
        <div className="news-text">{text}</div>
        <button className="learn-more-btn" onClick={() => window.location.href = url}>
            Learn More
        </button>
    </div>
);

const Product = ({ title, description, image, link }) => (
    <div className="product-bubble">
        <img src={image} alt="" className="product-image" />
        <div className="product-title">{title}</div>
        <div className="product-text">{description}</div>
        <button className="learn-more-btn" onClick={() => window.location.href = link}>
            Learn More
        </button>
    </div>
);

const Home = () => {
    const currentNews = [
        {
            url: "https://www.youtube.com/watch?v=YZp3Hy6YFqY",
            title: "KEEPING UP WITH AI",
            bubbleTitle: "AI Agents",
            text: "AI agents are like super-smart digital buddies...",
        },
        {
            url: "https://www.youtube.com/watch?v=SBoen3q5AoQ",
            title: "ROBOTICS",
            bubbleTitle: "NVIDIA",
            text: "NVIDIA is supercharging robots with brainpower...",
        },
    ];

    const products = [
        {
            title: "Speaking & Engagement",
            description: "Discover how AI is revolutionizing the world...",
            image: aispeaking,
            link: "/speaking",
        },
        {
            title: "The Prompt",
            description: "Keep pace with the rapidly evolving world of AI...",
            image: theprompt,
            link: "/the-prompt",
        },
    ];

    const features = [
        {
            title: "AI Workshops",
            description: "Join our hands-on workshops to learn the latest in AI technology and applications.",
            image: aiWorkshopImage, // Use image instead of icon
        },
        {
            title: "Research & Development",
            description: "Explore cutting-edge AI research and development projects led by our team of experts.",
            image: rndImage, // Use image instead of icon
        },
        {
            title: "Community Engagement",
            description: "Be part of a vibrant AI community. Attend events, network, and collaborate with like-minded individuals.",
            image: communityImage, // Use image instead of icon
        },
    ];

    const [email, setEmail] = useState("");
    const [joinedMailingList, setJoinedMailingList] = useState("");

    const handleMailingList = async (e) => {
        e.preventDefault();
        if (!email) return;

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list`, { email });
            setJoinedMailingList("Joined");
            setEmail("");
        } catch {
            setJoinedMailingList("Error");
        }
    };

    return (
        <div className="home-container">
            {/* Hero Section */}
            <div className="frontpage">
                <div className="frontpage_section">
                    <div className="title_box_subtitle" style={{ fontSize: "130px", color: "white" }}>AI CORE</div>
                </div>
            </div>

            {/* Feature Blocks Section */}
            {/* <div className="features-section">
                {features.map((feature, index) => (
                    <FeatureBlock key={index} {...feature} />
                ))}
            </div> */}

            {/* Products Section */}
            <div className="products-section">
                {products.map((product, index) => (
                    <Product key={index} {...product} />
                ))}
            </div>

            {/* News Section */}
            <div className="news-section">
                {currentNews.map((news, index) => (
                    <CurrentNews key={index} {...news} />
                ))}
            </div>

            {/* Mailing List Section */}
            <div className="mailing-list-section">
                <h2>Join Our Mailing List</h2>
                <p>Stay updated with AI Core and receive our monthly newsletter, The Prompt!</p>
                <form onSubmit={handleMailingList} className="mailing-list-form">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit">Join the list</button>
                </form>
                {joinedMailingList && (
                    <div className="mailing-list-feedback">
                        {joinedMailingList === "Joined" ? "Thanks for joining our mailing list :)" : "Error. Try again later."}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;