import React, { useEffect } from "react";

import titleImage from '../images/mainframe.png';
import '../styles.css';

const SponsorUs = () => {

    useEffect(() => {
        document.title = "Sponsor Us | AI Core";
    }, []);
    
    return (
        <>
        <div className="container">

            <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
                <div className="animated_title">Sponsor Us</div>
            </div>
        </div>
        </>
    );
}

export default SponsorUs;