import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./Card"; // Make sure to adjust the import path
import "../styles.css"; // Make sure to include your styles

const CustomGPTs = (props) => {
  const {
    netID,
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    messagesInConversation,
    setMessagesInConversation,
    GPT,
    setGPT,
    threadID,
    setThreadID,
    theme,
    token,
  } = props;

  const [selectedModel, setSelectedModel] = useState(GPT);
  const [vectorStoreID, setVectorStoreID] = useState("");
  const [assistantID, setAssistantID] = useState("");
  const [codeInterpreter, setCodeInterpreter] = useState(false);

  const navigate = useNavigate();

  const models = [
    {
      group: "A",
      image: require("../images/jojo.webp"),
      title: "Dungeons and JoJo",
      subtitle:
        "Dungeon Master GPT: Embark on a Thrilling Adventure: Create Unique Characters, Battle with Stands, and Uncover Mysterious Plots in Dungeons and JoJo! Experience Endless Fun with Dynamic Gameplay and Immersive Storytelling.",
      name: "- Nnanyere Okoro",
      assistantID: "asst_CEQI9xG2zPDt2i9PhCpAuXhP",
      vectorStoreID: "",
      codeInterpreter: false,
    },
    {
      group: "A",
      image: require("../images/kitchen.webp"),
      title: "Kevin's Kitchen Assistant",
      subtitle:
        "Kevin's Kitchen Assistant: Your Personalized Cooking Guide. Discover Delicious Recipes, Learn Essential Techniques, and Create Mouthwatering Dishes with Confidence!",
      name: "- Kevin",
      assistantID: "asst_QwRP01A5o3PCZVoQQYedl0xi", 
      vectorStoreID: "", 
      codeInterpreter: false
    },
    {
      group: "B",
      image: require("../images/mbti.webp"),
      title: "MBTI Test Bot",
      subtitle:
        "Discover Your Personality Type: Take the MBTI Test and Unlock Insights into Your Strengths, Weaknesses, and Ideal Career Paths. Gain a Deeper Understanding of Yourself and Others!",
      name: "- Vivian Huynh",
      assistantID: "asst_WaLWYfAglLO1HYIl0igtopoj",
      vectorStoreID: "",
      codeInterpreter: false
    },
    {
      group: "C",
      image: require("../images/pokemon.webp"),
      title: "Pokemon AI Assistant",
      subtitle:
        "Master Pokémon Battles with Expert Insights. Discover strengths, weaknesses, and ideal matchups. Build balanced teams for every stage of your Pokémon journey!",
      name: "- Joseph",
      assistantID: "asst_FWrijxF2zgAFtUFYQEjnUNdV",
      vectorStoreID: "",
      codeInterpreter: true,
    },
    {
      group: "C",
      image: require("../images/maze.webp"),
      title: "Levi's Maze Assistant",
      subtitle:
        "Generate intricate mazes and explore the paths with interactive guidance. Test your skills, avoid pitfalls, and celebrate your victories!",
      name: "- Levi",
      assistantID: "asst_JyOhgBzxxVkCYvP82QvtCxTv",
      vectorStoreID: "",
      codeInterpreter: false,
    },

    {
      group: "C",
      image: require("../images/study.webp"),
      title: "Study Advisor",
      subtitle:
        "Tailored Study Guidance for Every Subject: Discover Optimal Methods, Create Customized Schedules, and Receive Continuous Feedback. Enhance Your Learning Efficiency and Achieve Your Goals!",
      name: "- Alex Myers",
    },
    {
      group: "D",
      image: require("../images/birdwatching.webp"),
      title: "Tucson Birdwatching Bot",
      subtitle:
        "Explore Tucson's vibrant birdlife with expert guidance. Discover prime birdwatching spots, identify local species, and learn about conservation efforts. Your ultimate birdwatching companion awaits!",
      name: "- Seulgi Chung",
      assistantID: "asst_tdAZlglBWa1RMXsZpcRuCwS4",
      vectorStoreID: "vs_KDTEha0nC8IzVnnOIDic8Dpz",
      codeInterpreter: false,
    },

    {
      group: "D",
      image: require("../images/chess.webp"),
      title: "Ruy Lopez Chess Opening V.2",
      subtitle:
        "Master the Ruy Lopez: Expert Chess Strategies, Move Analysis, and Interactive Guidance for Improving Your Opening Game. Unlock your potential with in-depth tutorials and personalized tips.",
      name: "- Aidan Rains",
      assistantID: "asst_Rem3H23Iowg0UTpKcBZD8ZGM",
      vectorStoreID: "",
      codeInterpreter: false,
    },
    {
      group: "D",
      image: require("../images/sedona.webp"),
      title: "Excursion Amigos",
      subtitle:
        "Discover Arizona's best eateries and activities for your road trip. Get personalized recommendations for each city and town. Explore hidden gems and local favorites to make the most of your Arizona adventure",
      name: "- Danielle N. Cunes",
      assistantID: "asst_NwsICEofluHYIteUt9LhhjLg",
      vectorStoreID: "vs_D0RkFOOpEpm673Y7r4ySBcDu",
      codeInterpreter: false,
    }
    // Add more models here
  ];

  const handleCardClick = (model) => {
    setSelectedModel(model);
    setGPT(model.title);
    console.log(model);
    navigate("/custom-chat");
  };

  return (
    <>
      <div className="animated_bg">
        <div className="animated_title">Custom GPTs</div>
      </div>
      <div className="grid-container">
        {models.map((model, index) => (
          <Card
            key={index}
            image={model.image}
            title={model.title}
            subtitle={model.subtitle}
            name={model.name}
            onClick={() => {
              handleCardClick(model);
              setAssistantID(model.assistantID);
              setCodeInterpreter(model.codeInterpreter);
              setVectorStoreID(model.vectorStoreID);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default CustomGPTs;
