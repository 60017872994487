import React, { useEffect, useRef, useState } from "react";
import BrainCanvas from "./BrainCanvas";
import RetailXRCanvas from "./RetailXRCanvas";
import ReactPlayer from "react-player";
import "../../styles.css";
import "./WildcatWelcome.css";
import HealthXRCanvas from "./HealthXRCanvas";
import dune from "../../images/dune4.webp";

const WildcatWelcome = () => {
  const scienceXR = useRef(null);
  const retailXR = useRef(null);
  const healthXR = useRef(null);

  const [hoveredProject, setHoveredProject] = useState(null);
  const [isListHovered, setIsListHovered] = useState(false);

  // Scroll to the clicked project
  const scrollToClickProject = (name) => {
    let ref = null;
    switch (name) {
      // Add more projects if needed here
      case "Scientific XR Visualization Platform":
        ref = scienceXR;
        break;
      case "Retail Sciences XR/AI Lab":
        ref = retailXR;
        break;
      case "XR for Health Innovation":
        ref = healthXR;
        break;
      default:
        break;
    }

    // Check if ref is not null before scrolling
    if (ref !== null) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const projectNames = [
    "Scientific XR Visualization Platform",
    "Retail Sciences XR/AI Lab",
    "XR for Health Innovation",
    "Pima County Visitor's Center NPC AI",
    "Vault Learning",
    "Joyful Jobs",
    "Radian",
    "City of Tucson Chatbot",
    "Cybersecurity CTF Gaming League",
    "The Play District",
  ];

  return (
    <>
      <div className="wildcat-welcome">
        <section
          className={`intro-section ${isListHovered ? "hovered" : ""}`}
          style={{
            backgroundImage: `url(${dune})`,
          }}
        >
          <div className="intro-content">
            {projectNames.map((name) => (
              <button
                key={name}
                className="specified-button"
                onClick={() => scrollToClickProject(name)} // Scroll to the section when button is clicked
              >
                {name}
              </button>
            ))}
            <div className="intro-project-list">
              <div className="intro-content">
                <div className="intro-project-list"></div>
              </div>
            </div>
          </div>
          {/* <div className="main-container">
            <div className="text-content">
              <h1 className="small-title">AI is changing the world</h1>
              <h1 className="big-title">So will you</h1>
              <h1 className="big-title">AI CORE</h1>
            </div>
          </div> */}
        </section>

        <section ref={scienceXR} className="section project-section">
          <h2>Scientific XR Visualization Platform</h2>
          <BrainCanvas />
          <p className="project-text">
            Discover the cutting-edge world of interactive brain scans with the
            Scientific XR team. Partnering with the Catalyst Studios at the UA
            Main Library and scientists at the University of Arizona, this
            project brings real scientific data into an immersive 3D environment
            using Unreal Engine. These brain scans, placed in extended reality,
            are ready to be experienced using the Meta Quest 3. Stay tuned for
            more updates!
          </p>
        </section>

        <section ref={retailXR} className="section project-section">
          <h2>Retail Sciences XR/AI Lab</h2>
          <RetailXRCanvas />
          <ReactPlayer
            url="https://vimeo.com/999964422?share=copy"
            playing={true}
            loop={true}
            muted={true}
            className="xr-health"
            width="100%"
            height="100%"
          />
          <p className="project-text">
            Partnering with UArizona Norton School of Human Ecology this team
            will invent next-generation experiences and discover of Augmented
            Reality, Computer Vision, and Agentic LLM structures in Norton's
            state-of-the-art Retail Sciences Laboratory. A true-to-life retail
            space complete with product, mood music, and rigged for maximum AI
            and XR - shopping may never be the same!
          </p>
        </section>

        <section ref={healthXR} className="section project-section">
          <h2>XR for Health Innovation</h2>
          <HealthXRCanvas />
          <ReactPlayer
            url="https://vimeo.com/1000207758?share=copy"
            playing={true}
            loop={true}
            muted={true}
            className="xr-health"
            width="100%"
            height="100%"
          />
          <p className="project-text">
            Professor Matthew Briggs of the University of Arizona Health
            Sciences Design leads development this summer in pursuit of novel
            techniques leveraging LLM and Computer Vision models to create
            convincing PBR Textures for medical simulation. By pioneering
            physically based rendering, the team will create a "text-
            to-texture" modality for AI - bringing to life highly realistic
            avatars and NPCs in real-time.
          </p>
        </section>
      </div>
    </>
  );
};

export default WildcatWelcome;
