import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackIcon.css';

const BackIcon = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  };

  return (
    <div onClick={goBack}> 
      <button className="back-icon">
        <i className="material-symbols-outlined" style={{ color: "white" }}>arrow_back</i> 
      </button>
    </div>
  );
};

export default BackIcon;