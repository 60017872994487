import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Preload, useGLTF } from '@react-three/drei'
import CanvasLoader from '../Loader'

// Absolute paths for public folder assets
const gltfModels = [
  '/HandGLTFV4/StaticMesh.gltf',  // Now using absolute path from public folder
  '/MelanomaGLTF_V3/APPROX_StaticMesh.gltf'
]

const ModelScans = ({ isMobile, gltfModelIndex }) => {
  try {
    const gltfModel = useGLTF(gltfModels[gltfModelIndex])
    
    return (
      <group>  {/* Changed from mesh to group for proper light handling */}
        <spotLight 
          position={[0, 10, 10]}
          angle={Math.PI/4}       // Reduced angle for better focus
          penumbra={0.5}
          intensity={500}         // Increased intensity
          decay={1}               // Adjusted decay for better light falloff
          castShadow
          shadow-mapSize={2048}
        />
        <hemisphereLight 
          intensity={2}           // Reduced intensity
          groundColor="#ffffff"
        />

        <primitive 
          object={gltfModel.scene}
          scale={isMobile ? 0.02 : 0.03}
          position={[0, 5, 0]}    // Simplified position
          rotation={[0, 0, 0]}
        />
      </group>
    )
  } catch (error) {
    console.error('Model load error:', error)
    return null  // Fallback for error boundary
  }
}

// Preload models at app start
gltfModels.forEach(model => useGLTF.preload(model))

const HealthXRCanvas = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    const handler = e => setIsMobile(e.matches)
    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [])

  return (
    <div style={{ 
      position: 'relative', 
      width: isMobile ? '20em' : '40em', 
      height: '20rem' 
    }}>
      <Canvas
        frameloop="demand"
        shadows
        camera={{ position: [20, 3, 5], fov: 70 }}
        gl={{ preserveDrawingBuffer: true }}
      >
        <Suspense fallback={<CanvasLoader />}>
          <OrbitControls
            enableZoom={true}
            autoRotate
            enablePan
            maxPolarAngle={Math.PI/2}  // Prevent camera flipping
          />
          
          <ModelScans isMobile={isMobile} gltfModelIndex={1} />
        </Suspense>
        
        <Preload all />
      </Canvas>
    </div>
  )
}

export default HealthXRCanvas
