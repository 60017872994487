import React, { useState, useEffect } from "react";
import axios from "axios";

import '../styles.css';

const CMS = () => {

    useEffect(() => {
        document.title = "Content Management | AI Core";
    }, []);
    
    return (
        <>
        <div className="content_block" style={{ background: "#e2e9eb" }}>
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "30px" }}>Content Management</div>

                <div style={{ background: "white", padding: "20px", paddingBottom: "15px", paddingLeft: "25px", paddingRight: "25px", boxSizing: "border-box", borderRadius: "10px" }}>
                    <div className="bubble_title" style={{ marginBottom: "10px", color: "var(--blue)" }}>Custom Site Pages</div>
                    <div className="bubble_text">There are no user-created pages live on the site yet.</div>

                    <button type="button" style={{ marginTop: "20px" }} className="button"><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>add</i> New page</button>
                </div>

                <div className="horizontal_line" style={{ marginTop: "20px", marginBottom: "20px" }}></div>

                <div style={{ background: "white", padding: "20px", paddingBottom: "15px", paddingLeft: "25px", paddingRight: "25px", boxSizing: "border-box", borderRadius: "10px" }}>
                    <div className="bubble_title" style={{ marginBottom: "10px", color: "var(--blue)" }}>Drafts</div>
                    <div className="bubble_text">Pages that are preview-only will appear here.</div>

                    <button type="button" style={{ marginTop: "20px" }} className="button"><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>add</i> New page</button>
                </div>
            </div>
        </div>
        </>
    );
}

export default CMS;