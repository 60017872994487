import React from 'react';

import '../styles.css';

const ChatIcon = () => {

    return (
        <div onClick={() => {window.location.href = '/chat'}}>
            <button className="chat-icon">
                <i className="material-symbols-outlined" style={{ color: "white" }}>chat</i>
            </button>
        </div>
    );
};

export default ChatIcon;