import React, { useState, useEffect } from "react";

import "../styles.css";

const ShowcaseCardBubble = (props) => {
  const { title, subtitle, image } = props;

  return (
    <div
      className="bubble2"
      style={{ minHeight: "1000px", textAlign: "center" }}
    >
      <div
        className="title_box_title"
        style={{
          fontSize: "50px",
          marginBottom: "30px",
          wordBreak: "break-word",
          color: "var(--blue)",
        }}
      >
        {title}
      </div>
      <div
        className="title_box_subtitle"
        style={{
          display: "block",
          fontSize: "20px",
          width: "95%",
          lineHeight: "2",
          color: "var(--blue)",
        }}
      >
        {subtitle}
      </div>
      <img
        src={image}
        style={{
          width: "90%",
          maxHeight: "60%",
          borderRadius: "25px",
          aspectRatio: "4/3",
          display: "block",
          position: "absolute",
          bottom: "5%",
          left: "5%",
        }}
      />
    </div>
  );
};

export default ShowcaseCardBubble;
