import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import titleImage from '../images/world2.png';

import '../styles.css';

import ShowcaseCardBubble from "./ShowcaseCardBubble";

const PortfolioProjectImages = [
    //  health
    require('../images/summerProject/XRForHealth.webp'),
    require('../images/summerProject/ScientificXRVisualizationPlatform.webp'),
    require('../images/summerProject/learningVault.webp'),

    //  business
    require('../images/summerProject/radian.webp'),
    require('../images/summerProject/XRForRetail.webp'),
    require('../images/summerProject/JoyfulJobs.webp'),
    require('../images/summerProject/AlioAI.webp'),
    require('../images/summerProject/AICoreMedia&EventTeam.webp'),

    // culture
    require('../images/summerProject/TucsonChatbot.webp'),
    require('../images/summerProject/cybersecurity.webp'),
    require('../images/summerProject/ThePlayDistrict3.webp'),
    require('../images/summerProject/mysteryProject.webp')

];

const Portfolio = () => {

    useEffect(() => {
        document.title = "Portfolio | AI Core";
    }, []);

    const navigate = useNavigate();
    const [B, setB] = useState(0);

    const portfolioProjects = [
        //  health
        {
            "title": "XR for Health Innovation",
            "description": "Professor Matthew Briggs of the University of Arizona Health Sciences Design leads development \
                this summer in pursuit of a novel techniques leveraging LLM and Computer Vision models to create convincing \
                PBR Textures for medical simulation. By pioneering physically based rendering, the team will create a \"text-\
                to-texture\" modality for AI - bringing to life highly realistic avatars and NPCs in real-time."
        },
        {
            "title": "Scientific XR Visualization Platform",
            "description": "With our partners at CATalyst Studios Main Libraries this game dev team is authoring an XR \
                platform for the visualization of UArizaona scientific data. This summer, explore the inner topology of the \
                human neural network as revealed in FRMI brain scan data come to life in stunning virtual reality!"
        },
        {
            "title": "The Learning Vault",
            "description": "Speech, Language, and Hearing Sciences professor and lab crew team up with AI Core to apply \
                Web Applications and AI technology to the task of helping delayed-learning toddlers and young kids acquire \
                language skills. We'll also be exploring GenAI for improving visual communication strategies and the \
                cutting-edge technique of manufacturing synthetic data to accelerate AI reasoning capabilities."
        },
        //  business
        {
            "title": "Radian",
            "description": "Eller College of Management professors team up this summer to expand the Radian \
                360 Business Communications feedback and professional development platform - now \
                powered by AI. Over six years of high quality curated interviews and presentations data will be \
                processed and augmented to train foundation models in the arts of business communications - the \
                Eller way with chatbots, agents, and avatars. "
        },
        {
            "title": "Retail Sciences XR/AI Lab",
            "description": "Partnering with UArizona Norton School of Human Ecology this team will invent next-generation \
                experiences and discover of Augmented Reality, Computer Vision, and Agentic LLM structures in Norton's \
                state-of-the-art Retail Sciences Laboratory. A true-to-life retail space complete with product, mood music, \
                and rigged for maximum AI and XR - shopping may never be the same!"
        },
        {
            "title": "Joyful Jobs",
            "description": "Finding a job has been upended by AI this year, and maybe not in the best way for job seekers.\
                Arizona recruiter Joyful Jobs joins force with AI Core this summer to pioneer AI systems that work\
                for job applicants and the recruiters that place them. LLMs, Agents, and GPTs unleashed to make\
                finding the right job possible - and joyful - again."
        },
        {
            "title": "Alio AI",
            "description": "Cross-border advanced manufacturing firm Alio IT partners with AI Core this summer to explore \
                LLM/RAG/Agentic architectures for automating shop floor decision making. with an emphasis on decades \
                of company know-how, safety, and economic productivity, this team aims to author a first-generation AI \
                for factory automation."
        },
        {
            "title": "AI Core Media & Events Team",
            "description": " An operation as large as the SECD / AI Core Summer Internship requires its own logistics, \
                marketing and professional development support. This team will capture the highlights and awesome product \
                outcomes from other teams, throw a few parties, do something amazing with a big TV, and capture our story \
                for our Fall 2024 nationwide marketing push. Also, game nights!"
        },
        //  Culture
        {
            "title": "City of Tucson Chatbot",
            "description": "What does it take to deploy a first generation chatbot capable of being the face of an entire \
                US city? AI Core! This team will learn the ropes of Prompt Engineering, GPTs, RAG, and vector embedding techniques \
                to launch the first-of-its-kind civic chatbot. Certain to become a feature of 21st century urban life, we'll \
                explore text, image, voice modalities, avatars, and more."
        },
        {
            "title": "Cybersecurity CTF Gaming League",
            "description": "Pima Community College's excellent Cyberwarfare Range and UArizona's AI Core partner this \
                summer to explore a capture-the-flag cybersecurity tournament competition for Arizona in 2025 and beyond.\
                Firing-up with an AI twist, which team will take down all others in the all-out AI-powered cyber defense/offense\
                battle royale? And how many languages can we live stream this in?"
        },
        {
            "title": "The Play District",
            "description": "Southern Arizona specialists in all things fun and family-friendly The Play District will\
                collaborate with AI Core this summer to explore the power of GenAI and GPTs, taking local \
                search to an all new level. This team will leverage Agents and custom LLMs while working \
                closely with their industry mentor to make AI work for parents and kids all over."
        },
        {
            "title": "Pima County Visitor's Center NPC AI",
            "description": "This summer, AI Core teams up with tourism pros at Pima County's Visitor's Center to explore \
                Animated AI Non-Player Character technology to tell the story of Tucson and Pima County."
        }
    ]

    return (
        <>
        <div className="frontpage_portfolio">
            <div className="frontpage_img" style={{height:"60vh"}}> </div>
            <div className="title_box_title" style={{marginTop: "100px", marginLeft: "20%", fontSize: "130px", color: "var(--blue)" }}>AI CORE</div>
            <div className="title_box_subtitle" style={{marginLeft: "20%", marginBottom: "100px", fontSize: "50px", color: "var(--blue)" }}>SUMMER 2024 PROJECT PORTFOLIO</div>
        </div>

        
        <div style={{display: "block", backgroundColor: "#f4ede5", height: "130px", fontSize: "100px", textAlign: "center", color: "var(--blue)"}}> HEALTH </div>
        <div className="portfolio_container" style={{padding: "40px", margin: "0 auto"}}>
            
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", borderRadius: "25px", padding: "10px"}}>
                
                <ShowcaseCardBubble title = {portfolioProjects[0].title} subtitle = {portfolioProjects[0].description} image = {PortfolioProjectImages[0]} />
                <ShowcaseCardBubble title = {portfolioProjects[1].title} subtitle = {portfolioProjects[1].description} image = {PortfolioProjectImages[1]} />
                <ShowcaseCardBubble title = {portfolioProjects[2].title} subtitle = {portfolioProjects[2].description} image = {PortfolioProjectImages[2]} />

            </div>

        <div style={{display: "block", backgroundColor: "#f4ede5", height: "130px", fontSize: "100px", textAlign: "center", color: "var(--blue)"}}> BUSINESS </div>
        <div className="portfolio_container" style={{padding: "40px", margin: "0 auto"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", borderRadius: "25px", padding: "10px"}}>
                
                <ShowcaseCardBubble title = {portfolioProjects[3].title} subtitle = {portfolioProjects[3].description} image = {PortfolioProjectImages[3]} />
                <ShowcaseCardBubble title = {portfolioProjects[4].title} subtitle = {portfolioProjects[4].description} image = {PortfolioProjectImages[4]} />
                <ShowcaseCardBubble title = {portfolioProjects[5].title} subtitle = {portfolioProjects[5].description} image = {PortfolioProjectImages[5]} />
                <ShowcaseCardBubble title = {portfolioProjects[6].title} subtitle = {portfolioProjects[6].description} image = {PortfolioProjectImages[6]} />          
                <ShowcaseCardBubble title = {portfolioProjects[7].title} subtitle = {portfolioProjects[7].description} image = {PortfolioProjectImages[7]} />

            </div>
        </div>

        <div style={{display: "block", backgroundColor: "#f4ede5", height: "130px", fontSize: "100px", textAlign: "center", color: "var(--blue)"}}> CULTURE </div>
        <div className="portfolio_container" style={{padding: "40px", margin: "0 auto"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", borderRadius: "25px", padding: "10px"}}>
                
                <ShowcaseCardBubble title = {portfolioProjects[8].title} subtitle = {portfolioProjects[8].description} image = {PortfolioProjectImages[8]} />          
                <ShowcaseCardBubble title = {portfolioProjects[9].title} subtitle = {portfolioProjects[9].description} image = {PortfolioProjectImages[9]} />          
                <ShowcaseCardBubble title = {portfolioProjects[10].title} subtitle = {portfolioProjects[10].description} image = {PortfolioProjectImages[10]} />          
                <ShowcaseCardBubble title = {portfolioProjects[11].title} subtitle = {portfolioProjects[11].description} image = {PortfolioProjectImages[11]} />          

            </div>
        </div>
            
        </div>
        </>
    );
}

export default Portfolio;