import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import e from '../images/e.wav'
import topbarLogo from '../images/ua_wordmark.svg';

import Popup from "./Popup";
import '../styles.css';

const TopbarCustomGPT = ( props ) => {
    const { setMessages, GPT, setGPT, threadID, setThreadID, netID, setNetID, setToken } = props;

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState(null);
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let login_url = backend_url + "/routes/login";
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenModel, setDropdownOpenModel] = useState(false);

    const isChatRoute = window.location.pathname === "/custom-chat";

    // Handles GPT selection
    const selectGPT = (selectedGPT) => {
        setGPT(selectedGPT);
        localStorage.setItem('GPT', JSON.stringify(selectedGPT));
        localStorage.removeItem('messages'); // Clear messages from local storage
        localStorage.removeItem('threadID'); // Clear thread from local storage
        setThreadID(null);
        setDropdownOpenModel(!dropdownOpenModel);
    };

    // Opens hamburger menu (mobile only)
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const MenuIcon = () => {
        return menuOpen ? 
            <i className="material-symbols-outlined inline-icon" style={{ color: 'white' }}>close</i> : 
            <i className="material-symbols-outlined inline-icon" style={{ color: 'white' }}>menu</i>;
    };

    // Clears chatbot history
    const clearHistory = () => {
        localStorage.removeItem('messages'); // Clear messages from local storage
        localStorage.removeItem('threadID'); // Clear thread from local storage
        setThreadID(null);
        setPopup(false);
    };

    // Function to open a popup if needed
    const handlePopup = (title, text, button_text = "", button_function = () => {}) => {
        setPopupData({ title, text, button_text, button_function });
        setPopup(true);
    };

    // Function to toggle the dropdown menu
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const toggleDropdownModel = () => {
        setDropdownOpenModel(!dropdownOpenModel);
    }; function play() {new Audio(e).play()}

    // Topbar dropdown menu
    const ModelDropdownMenu = () => {
        return (
            <div className={`model_dropdown_menu ${dropdownOpenModel ? 'show' : ''}`}>
                <button className="pick_model_button" onClick={() => {selectGPT('GPT-4o')}}>
                    <img className="pick_gpt_image" src={require('../images/gpt4.png')} alt=""/>
                    <div className={`pick_model_text ${GPT === "GPT-4o" && "model_active"}`}>GPT-4o</div><br/>
                </button>
            </div>
        );
    };

    // Function to log out
    const logout = () => {
        localStorage.setItem("netID", null)
        localStorage.setItem("token", null)
        setNetID(null);
        setToken(null);
        navigate('/');
    };
    
    return (
        <>
        <Popup popupData={popupData} popup={popup} setPopup={setPopup} GPT={GPT} setGPT={setGPT} setThreadID={setThreadID}/>

        <header className="topbar" style={{ paddingLeft: isChatRoute ? "260px" : "" }}>
            {!isChatRoute && 
            <div className={`red_banner`}>
                <div className="red_banner_container">
                    <a href="https://www.arizona.edu"><img className="topbar_logo" src={topbarLogo} alt="Topbar Logo"/></a>

                    {!isChatRoute && <>
                        <button type="button" className="mobile_menu_button" onClick={toggleMenu}><MenuIcon/></button>
                        {(!netID || netID === "null") ? <a href={login_url}><button className="other_menu_button">Log in <i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>login</i></button></a>
                        : <button className="netid_menu_button" onClick={toggleDropdown}>{netID}</button>}</>}
                </div>
            </div>}

            <ModelDropdownMenu/>
        </header>
        </>
  )
}

export default TopbarCustomGPT;
