import React, { useEffect, useState } from 'react';
import BrainCanvas from '../WildcatWelcome/BrainCanvas'; // Brain model
import RetailXRCanvas from '../WildcatWelcome/RetailXRCanvas'; // Mannequin model for Library
import './ScavengerHunt.css'; // Scavenger Hunt Styles

// Define multiple target locations with clues & associated 3D models
const locations = [
  {
    name: "Old Main",
    latitude: 32.239232,    
    longitude: -110.947137,  
    clue: "The historic heart of campus where tradition meets innovation.",
    model: <BrainCanvas />  // Brain Model
  },
  {
    name: "Library",
    latitude: 32.231419,    
    longitude: -110.948960,  
    clue: "Where knowledge is treasured—find hints among the stacks.",
    model: <RetailXRCanvas />  // Mannequin Model
  },
  {
    name: "Stadium",
    latitude: 32.238500,    
    longitude: -110.948000,  
    clue: "Feel the roar of the crowd as you approach this iconic arena.",
    model: null // You can add a model here later
  }
];

const allowedRadius = 50; // Allowed distance in meters

const ScavengerHunt = () => {
  const [currentLocationIndex, setCurrentLocationIndex] = useState(null);
  const [isAtLocation, setIsAtLocation] = useState(false);
  const [error, setError] = useState(null);
  const [checking, setChecking] = useState(false);
  const [page, setPage] = useState("clues");
  const [unlockedLocations, setUnlockedLocations] = useState([]);

  useEffect(() => {
    if (currentLocationIndex !== null) {
      setChecking(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude: userLat, longitude: userLon } = position.coords;
            const { latitude, longitude } = locations[currentLocationIndex];
            const distance = calculateDistance(userLat, userLon, latitude, longitude);
            
            if (distance <= allowedRadius) {
              setIsAtLocation(true);
              setUnlockedLocations(prev =>
                prev.includes(currentLocationIndex) ? prev : [...prev, currentLocationIndex]
              );
              setPage("unlocked");
            } else {
              setIsAtLocation(false);
              setPage("location-check");
            }
            setChecking(false);
          },
          (err) => {
            setError(err.message);
            setChecking(false);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
        setChecking(false);
      }
    }
  }, [currentLocationIndex]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; 
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(Δφ / 2) ** 2 +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  if (page === "clues") {
    return (
      <div className="scavenger-container">
        <header className="scavenger-header">
          <h2>Scavenger Hunt</h2>
          <p>Follow the clues below to unlock immersive experiences on campus.</p>
        </header>
        <div className="scavenger-clues">
          {locations.map((loc, index) => (
            <div key={index} className="clue-card">
              <h3 className="clue-title">{loc.name}</h3>
              <p className="clue-text">{loc.clue}</p>
              <button
                className="scavenger-button"
                onClick={() => {
                  setCurrentLocationIndex(index);
                  if (unlockedLocations.includes(index)) {
                    setPage("unlocked");
                  } else {
                    setPage("location-check");
                  }
                }}
              >
                {unlockedLocations.includes(index) ? "See 3D Model" : "Check Location"}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (page === "location-check") {
    return (
      <div className="scavenger-container">
        <div className="location-check">
          <h2>Checking Location</h2>
          <p>
            Move within {allowedRadius} meters of <strong>{locations[currentLocationIndex].name}</strong> to unlock the experience.
          </p>
          {checking && <p className="status-message">Checking your location…</p>}
          <button className="scavenger-button" onClick={() => setPage("clues")}>
            Back to Clues
          </button>
        </div>
      </div>
    );
  }

  if (page === "unlocked") {
    return (
      <div className="scavenger-container unlocked-container">
        <header className="scavenger-header">
          <h2>{locations[currentLocationIndex].name} Unlocked!</h2>
          <p>Explore the interactive 3D model below.</p>
        </header>
        <div className="unlocked-model">
          {locations[currentLocationIndex].model ? locations[currentLocationIndex].model : <p>No model available for this location.</p>}
        </div>
        <button className="scavenger-button" onClick={() => setPage("clues")}>
          Back to Clues
        </button>
      </div>
    );
  }

  return null;
};

export default ScavengerHunt;
