// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xr-container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.xr-canvas {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.vr-button {
    margin-bottom: 10px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    background-color: #385493;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background 0.3s;
}

.vr-button:hover {
    background-color: #2d3f7a;
}`, "",{"version":3,"sources":["webpack://./src/components/XRExperience/XRExperience.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".xr-container {\n    width: 100%;\n    height: 80vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.xr-canvas {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n}\n\n.vr-button {\n    margin-bottom: 10px;\n    padding: 12px 24px;\n    font-size: 16px;\n    cursor: pointer;\n    background-color: #385493;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    transition: background 0.3s;\n}\n\n.vr-button:hover {\n    background-color: #2d3f7a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
