import React, { useState, useEffect } from "react";
import axios from "axios";

import titleImage from '../images/campus.jpg';
import '../styles.css';

const RequestMeeting = () => {

    useEffect(() => {
        document.title = "Request a Zoom | AI Core";
    }, []);

    const [email, setEmail] = useState("");
    const [comments, setComments] = useState("");
    const [subscribe, setSubscribe] = useState(false);
    const [requested, setRequested] = useState("");

    // Handles requesting a zoom
    const handleRequest = async (e) => {
        e.preventDefault();
        if (!email) { return; }

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/request-a-zoom`,
                method: 'POST',
                responseType: 'json',
                data: { email: email, comments: comments, subscribe: subscribe },
            });

            // request success
            setRequested("Requested");
            setEmail("")
            setComments("")
            setSubscribe(false);
        } catch (error) {
            setRequested("Error");
        }
    };
    
    return (
        <>
        <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
            <div className="animated_title">Request a Zoom</div>
        </div>

        <div className="content_block" style={{ background: "#e2e9eb" }}>
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "20px" }}>Connect with Us</div>

                <div className="bubble_text">Fill out this form and we'll set up a time.</div>
                
                <form onSubmit={handleRequest} style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}>
                    <div className="form_label">Email address</div>
                    <input type="email" className="form" placeholder="wilburwildcat@arizona.edu " value={email} onChange={(e) => setEmail(e.target.value)}/>

                    <div className="form_label">What's this about? (optional)</div>
                    <textarea type="text" className="form" style={{ height: "140px" }} value={comments} onChange={(e) => setComments(e.target.value)}/>

                    <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" checked={subscribe} onChange={(e) => setSubscribe(e.target.checked)} />
                        <span style={{ marginLeft: "5px", fontSize: "16px", fontStyle: "italic" }}>Subscribe to our mailing list</span>
                    </div>
                    
                    <button type="submit" className="button" style={{ marginTop: "15px", marginBottom: "15px" }}>Submit Request</button>
                </form>

                {requested !== "" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{requested === "Requested" ? "Thanks for requesting a zoom! We'll respond as soon as we can." : "Internal server error."}</div>}
                {requested === "Error" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{`(${process.env.REACT_APP_BACKEND_URL}/routes/request-a-zoom did not return a response)`}</div>}
            </div>
        </div>
        </>
    );
}

export default RequestMeeting;