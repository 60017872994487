import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { VRButton, XR, DefaultXRController } from "@react-three/xr"; // Updated imports
import { OrbitControls, useGLTF, Environment } from "@react-three/drei";
import "./XRExperience.css"; // Custom styles

// Load AI Core Office Model
const AICoreOffice = () => {
    const { scene } = useGLTF("/AICORE_OFFICE/SM_merged_AICore-Office-mesh.gltf");
    return <primitive object={scene} scale={1} />;
};

const XRExperience = () => {
    return (
        <div className="xr-container">
            {/* VR Button for Entering XR Mode */}
            <VRButton className="vr-button" />

            {/* WebXR Canvas */}
            <Canvas className="xr-canvas" camera={{ position: [0, 1.5, 3], fov: 60 }}>
                <XR>
                    {/* Render VR Controllers */}
                    <DefaultXRController hand="left" />
                    <DefaultXRController hand="right" />

                    {/* Lighting */}
                    <ambientLight intensity={1.5} />
                    <directionalLight position={[2, 4, 5]} intensity={1.5} />

                    {/* 3D Environment */}
                    <Suspense fallback={<span>Loading AI Core Office...</span>}>
                        <AICoreOffice />
                    </Suspense>

                    {/* Controls */}
                    <OrbitControls enableZoom={true} />
                    <Environment preset="city" />
                </XR>
            </Canvas>
        </div>
    );
};

export default XRExperience;